import React from 'react';
import Container from '../../ui/Grid/Container';
import Row from '../../ui/Grid/Row';
import Col from '../../ui/Grid/Col';
import Headline from '../../ui/Headline';
import Accordion from '../../ui/Accordion';
import RichText from '../../ui/RichText';
import * as styles from './styles.module.scss';

type AccordionTeaserProps = {
  headline?: string;
  subHeadline?: string;
  text?: Document;
  accordionEntries: React.ComponentProps<typeof Accordion>['accordionEntries'];
  accordionType: React.ComponentProps<typeof Accordion>['accordionType'];
};

const AccordionTeaser = ({
  headline = undefined,
  subHeadline = '',
  text = undefined,
  accordionEntries,
  accordionType,
}: AccordionTeaserProps) => {
  return (
    <Container fullWidth additionalClass={styles.container}>
      <Row>
        <Col desktop={6} mobile={12} additionalClass="relative mb-50 tablet:mb-0">
          <div className={styles.scrollWrapper}>
            {subHeadline && <p className="uppercase font-secondary">{subHeadline}</p>}
            {headline && (
              <Headline level={2} color="Black">
                {headline}
              </Headline>
            )}
            {text && <RichText content={text} additionalClass={styles.copyText} />}
          </div>
        </Col>
        <Col desktop={6} mobile={12}>
          <Accordion accordionEntries={accordionEntries} accordionType={accordionType} />
        </Col>
      </Row>
    </Container>
  );
};

export default AccordionTeaser;
