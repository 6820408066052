import React, { useState } from 'react';
import cn from 'classnames';
import { Settings } from 'react-slick';
import Icon from '../Icon';
import RichText from '../RichText';
import ContentSlider from '../ContentSlider';
import * as styles from './style.module.scss';

export type AccordionProps = {
  accordionEntries: Array<AccordionEntry>;
  accordionType: 'accordion' | 'list';
};

export type AccordionEntry = {
  headline: string;
  text: Document;
};

const Accordion: React.FC<AccordionProps> = ({ accordionEntries, accordionType }) => {
  const [activeItemId, setActiveItemId] = useState(null);

  const toggle = (index: number) => {
    if (activeItemId === index) return setActiveItemId(null);
    return setActiveItemId(index);
  };

  const settings: Settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    className: 'center',
    centerPadding: '15px',
    infinite: true,
    autoplay: false,
    autoplaySpeed: 3000,
    dots: true,
    responsive: [
      {
        breakpoint: 767,
      },
      {
        breakpoint: 10000,
        settings: 'unslick',
      },
    ],
  };

  return (
    <div className={cn(styles.container, { [styles.isAccordion]: accordionType === 'accordion' })}>
      <ContentSlider additionalSettings={settings}>
        {accordionEntries.map((accordionEntry, index) => (
          <div className={cn('mb-90', styles.item, { [styles.isActive]: activeItemId === index })} key={index}>
            <div
              onClick={accordionType === 'accordion' ? () => toggle(index) : () => {}}
              role="button"
              tabIndex={0}
              onKeyDown={e => e.code === '13' && toggle(index)}
              className="flex flex-row content-center outline-none"
            >
              <p className={styles.entryHeadline}>{accordionEntry.headline}</p>
              <Icon
                name="arrow"
                width={15}
                additionalClass={cn(styles.icon, activeItemId === index && styles.iconActive)}
              />
            </div>
            <div className={styles.text}>
              <RichText content={accordionEntry.text} />
            </div>
          </div>
        ))}
      </ContentSlider>
    </div>
  );
};

export default Accordion;
